import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import { paymentLink } from "@/staticData/constant";

export const removeHtmlTags = (inputString) => {
  return inputString?.replace(/<\/?[^>]+(>|$)/g, "");
};

export const removeHtmlSpace = (inputString) => {
  return inputString?.replace(/&nbsp;/g, " ");
};

export const removeDecimals = (number) => {
  return parseInt(number.toFixed(0), 10);
};

export const formatDate = (givenDate) => {
  const year = givenDate.getFullYear();
  const month = String(givenDate.getMonth() + 1).padStart(2, "0");
  const day = String(givenDate.getDate()).padStart(2, "0");
  const hour = String(givenDate.getHours()).padStart(2, "0");
  const minute = String(givenDate.getMinutes()).padStart(2, "0");

  return { date: `${day}-${month}-${year}`, time: ` ${hour}:${minute}` };
};

export const checkTimeDifferenceLessThan24hrs = (date1, date2) => {
  const timeDifference = Math.abs(date1 - date2);
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;
  return timeDifference < millisecondsIn24Hours;
};

const currentDate = new Date();
export const monthYearString = currentDate.toLocaleString("default", {
  month: "short",
  year: "numeric",
});

export const capitalizeWords = (
  inputString,
  separator = " ",
  keepSeparator = true
) => {
  if (typeof inputString !== "string" || inputString?.length === 0) {
    return inputString;
  }
  const words = inputString.split(separator);
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(keepSeparator ? separator : " ");
};
export function getRandomElementFromArray(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export const roundDownToNearestTens = (num, radix = 10) => {
  return num > radix ? Math.floor(num / radix) * radix : num;
};

export const parseToNumber = (str) => {
  const number = parseInt(str, 10);
  return isNaN(number) ? 0 : number;
};

export const getWindowWidth = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth;
  }
  return 0;
};

export const isiPhone = () => {
  if (typeof navigator == "undefined" || typeof window == "undefined") {
    return false;
  }
  return /iPhone/.test(navigator.userAgent) && !window.MSStream;
};

export const prepareListMapping = (list) => {
  let listObj = {};
  list.forEach((item) => {
    listObj[item.id] = item.label;
  });

  return listObj;
};

export const getPaymentLinkByTimeZone = () => {
  let link =
    ClientAppManager.instance.timeZone == "Asia/Calcutta"
      ? paymentLink.india
      : paymentLink.international;

  return link;
};
